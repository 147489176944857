.file-item {
  white-space: nowrap;
  user-select: none;
  display: flex;
  align-items: center;
  padding: 3px 0;

  &.context-click {
    background-color: lightblue;
  }

  cursor: pointer;
  .file-icon-container {
    margin-left: 5px;
  }

  &:hover {
    background-color: lightgray;
  }
}
