.icon-container {
  display: flex;
  align-items: center;
  height: 20px;
  width: 20px;

  &.open {
    svg {
      transform: rotate(90deg);
    }
  }
}
