.folder-item-conatiner {
  .folder-item {
    user-select: none;
    display: flex;
    align-items: center;
    padding: 3px 0;
    position: relative;
    z-index: 5;

    &.context-click {
      background-color: lightblue;
    }

    cursor: pointer;
    div {
      cursor: pointer;
    }

    .folder-name-button {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .item-menu {
      width: 21px;
      margin-right: 20px;
      opacity: 0;
      pointer-events: none;
    }

    &:hover {
      background-color: lightgray;

      .item-menu {
        position: relative;
        z-index: 10;
        opacity: 1;
        pointer-events: initial;
      }
    }
  }
}
