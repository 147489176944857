.main-folder-list-container {
  min-width: 250px;
  background-color: var(--color-panels);
  box-shadow: var(--shadow-panel);
  margin: 15px var(--margins-panel) 15px var(--margins-panel);
  overflow: hidden;
  //border: 1px solid black;
  border-radius: var(--radius-panel);
  position: relative;
  user-select: none;

  .folder-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    padding-left: 8px;
    margin-bottom: 8px;
  }

  .folder-padding {
    padding-left: 13px;
  }

  &.closed {
    border: none;
    max-width: 0;
    min-width: initial;
    overflow: hidden;
    box-shadow: none;
  }

  .main-folder-list {
    // border: 1px solid black;
    // width: fit-content;
    // min-width: 250px;

    overflow: auto;
    height: 100%;

    user-select: none;

    &.no-contents {
      display: flex;
      flex-direction: column;
      align-items: center;
      // padding-top: 25%;
    }
  }
}
