.file-icon-container {
  display: flex;
  align-items: center;
  width: 17px;
  justify-content: center;
  margin-right: 5px;
  img {
    width: 100%;
  }
}
